import React, { memo, useRef, useState } from "react"

 function SelectOption(props){
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState(props.items || [
        {name : "Select Option", value : ""},
        {name : "Option 1", value : "Option 1"},
        {name : "Option 2", value : "Option 2"},
        {name : "Option 3", value : "Option 3"},
        {name : "Option 4", value : "Option 4"},
    ])
    const refs = useRef();
    const refIcon = useRef();
    //[{value:1, name:"name"}]
    
    const onMouseUpSelectClose = ()=>{
        refs.current.style.display = "none"
        refs.current.classList.add("addSelectItems")
        refIcon.current.classList.add("addSelectIcon")
        setOpen(false)
    }
    function onClickOpenCloseList(){
        if(open === true){
            refs.current.style.display = "none"
            refIcon.current.classList.add("addSelectIcon")
            refs.current.classList.remove("addSelectItems")
        }else{
            refs.current.style.display = "block"
            refIcon.current.classList.remove("addSelectIcon")
            refs.current.classList.add("addSelectItems")
        }
        setOpen(!open)
    }
    const lists = items.map((items, index)=>{
        return(
            (items.value !== "")? <div  
            style={{padding:"10px 10px",borderBottom:"1px solid lightgray", cursor:"pointer"}}
            onMouseDown={()=>props.onClick(items.value)}
            onClick={onMouseUpSelectClose} 
            key={items.value+index}>
                {(props.check)? <div>
                    <input type="checkbox" id={items.name} name={items.name} onClick={props.onChecked} value={props.value} />
                    <label htmlFor={items.name}>{items.name}</label>
                </div>: items.name }
        </div>
        :null)
    })
    return(<>
        <div>
            <div className="select">
                <div className="select_value" onClick={()=>onClickOpenCloseList()}
                style={{backgroundColor:(open)? "#E5E8E8" : "white",
                color:"gray",fontSize:"0.9em"}}>{props.value}</div>
                <div className="select_icon" onClick={()=>onClickOpenCloseList()} ref={refIcon}> 
                    <i className="fa fa-chevron-left" aria-hidden="true"/>
                </div>
                <div className="select_items" ref={refs}>
                    {lists}
                </div>
            </div>
        </div>
    </>)
}

export default memo(SelectOption)