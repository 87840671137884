import React, { useRef } from "react";
import "./../../assets/css/main.css";
import { Button } from '@mui/material';
import Title from "./Title";
import InputData from "./InputData";
import DisplayData from "./DisplayData";
import { Actions } from "../store/store";



export default function Home(props){
    const refs = useRef();
    const refss = useRef()

    const onClickNext = ()=>{
        console.log("wizard clicked")
        refs.current.style.display= "block"
    }
    const onClickClose = ()=>{
        refs.current.style.display= "none"
        refss.current.style.display= "none";
        console.log("onclickclose")
    }
    const onClickDisplayData = ()=>{
        refss.current.style.display= "block";
    }
    
    return(<>
        <Title title="" />
        <div className="base">
            <div className="basea">
                
                <Button  className="" size="medium" 
                    onClick={onClickDisplayData}  
                    style={{ marginRight:"10px"}}> 
                   Data
                </Button>

                <Button  className="" size="medium" 
                    onClick={onClickNext}  
                    style={{}}> 
                    Wizard
                </Button>
            </div>
            <div className="baseb">
                <h1 className="" style={{fontSize:"5em", color: "#036EB6",fontWeight:"500"}}>ReactUI</h1>
                <div className="">Welcome <span style={{fontWeight:200}}>to</span> Join</div>
            </div>
            <div>
                <Actions />
            </div>
            <div className="basec">
                <h3 className="">All The Best</h3>
            </div>
        </div>
        <div className="box" ref={refs}>
            <InputData onClickClose={onClickClose}/>
        </div>
        <div className="box" ref={refss}>
            <DisplayData onClickClose={onClickClose}/>
        </div>
    </>)
}