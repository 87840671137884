import {createSlice,createSelector,configureStore} from "@reduxjs/toolkit";
import {Provider, useDispatch, useSelector} from "react-redux";
import "./../../assets/css/main.css";
import { useEffect, useState } from "react";

const cartSlice = createSlice({
    name : "Cart",
    initialState : [],
    reducers : {
        addItems : (state,action)=>{
            state.push(action.payload)
        },
        removeItems : (state,action)=>{
            console.warn(action.payload)
            let arr = state.filter((items,index)=>{
                    if(action.payload-2 !== index){
                        return items;
                    }
                })
            state.length = 0;
            state.push(...arr)
        },
    }
})

const {addItems, removeItems} = cartSlice.actions;
const cartReducer = cartSlice.reducer;

const store = configureStore({
    reducer : {
        cart : cartReducer,
    }
})

function Actions(props){
    const [count, setCount] = useState(0)
    const dispatch = useDispatch();
    const selecter = useSelector((state)=>state);
    useEffect(()=>{
        console.log(selecter.cart);
    },[selecter])
    const onClickAddDispatch = ()=>{
        setCount((count) => count + 1)
        dispatch(addItems({name : "tapendra", age : 25, count : count}))
    }
    function onClickRemoveDispatch(){
        if(count > 0){
            setCount((priview)=> priview -1)
            dispatch(removeItems(count))
        }
    }
    return(<>
        <div>
            <button onClick={onClickAddDispatch}
            style={{borderRadius:"20px",outline:"none",borderWidth:"1px",
            borderColor:"gray"
            }}>Add items</button>
            <span>Redux&toolkit ItemValue : console data</span>
            {/*<button onClick={()=>onClickRemoveDispatch()}
            style={{borderRadius:"20px",outline:"none",borderWidth:"1px",
            borderColor:"gray"
            }}>Remove Items</button>*/}
        </div>
    </>)
}

export {store,addItems,removeItems,Actions}