import React from "react";

export default function Title(props){
    return(<>
        {(props.title !== "")? 
            <title> DatePlatter | {props.title} </title>
        :
            <title>DatePlatter</title>
        }
    </>)
}