import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

const domain = "https://api.artic.edu";
const url = domain+"/api/v1/artworks/search?q=cats"

export default function DisplayData(props){
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    
    function FetchData(){
        const options = {
            method : "GET", 
            headers : { 
                "Content-Type" : "application/json", 
                "Accept" : "application/json"
            }
        }
        try{
            setLoading(true);
            fetch(url,options)
            .then((response)=> response.json())
            .then((result)=>{
                setLoading(false)
                if(result.data.length > 0){
                    setData(result.data)
                }else{
                    setData([])
                }
            })
            .catch((error)=>{
                setLoading(false)
                setError("Fetch API Internal error")
            })
        }catch(err){
            setLoading(false)
            console.log(err)
        }
    }
    const printData = data.map((items, indexs)=>{
        return(<div key={"data"+indexs} className="data_list">
            {/*<div>
                <span>Id : </span>
                <span>{items.id}</span>
            </div>*/}
            <div>

            </div>
            <div>
                <span>Score : </span>
                <span>{items._score}</span>
            </div>
            <div>
                <span>Title : </span>
                <span>{items.title}</span>
            </div>
            <div>
                <span>Thumbnail : </span>
                <span>{items.thumbnail.alt_text}</span>
            </div>
        </div>)
    })
    useEffect(()=>{
        FetchData()
    },[])
    return(<>
        <Button className="close" onClick={props.onClickClose}>X</Button>
        <div className="data">
            <div className="sub_data">
                {(data.length > 0)?
                    printData
                    : null
                }
            </div>
        </div>
    </>)
}