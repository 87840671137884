import React, { useCallback, useEffect, useRef, useState } from "react";
//import Title from "./Title";
import { Button } from "@mui/material";
import SelectOption from "./Select"
export default function InputData(props){
    const [formStatus, setFormStatus] = useState(false)
    const [pageno, setPageno] = useState(1)
    // a form
    const [selectval, setSelectval] = useState("Select Option")
    const [selectvalb, setSelectvalb] = useState("Select Option")
    const [selectvalc, setSelectvalc] = useState("Select Option")
    const [selectvald, setSelectvald] = useState("Select Option")
    const [selectvale, setSelectvale] = useState("Select Option")
    const [selectvalf, setSelectvalf] = useState("Select Option")
    const [selectvalg, setSelectvalg] = useState("Select Option")
    const [selectvalh, setSelectvalh] = useState("Select Option")
    const [optiona, setOptiona] =useState("")
    const [optionb, setOptionb] =useState("")
    const [optionc, setOptionc] =useState("")
    const [changeVala, setChangeVala] = useState("")
    const [changeValb, setChangeValb] = useState("")
    const [changeValc, setChangeValc] = useState("")
    const [changeVald, setChangeVald] = useState("")
    const [changeVale, setChangeVale] = useState("")
    const [listItems, setListItems] = useState([])
    const [saveStatus, setSaveStatus] = useState(false)
    // increment and decrement
    const [totalItems, setTotalItems] = useState(0)
    const [onStatus, setOnStatus] = useState(false)
    const refIncDec = useRef();
    const refSuccess = useRef();
    const btnrefs = useRef();
    const switchRef = useRef();
    const formRefs = useRef()
    const refia = useRef();
    const refib = useRef();
    const refic = useRef();
    const refid = useRef();
    const refie = useRef();
    const progressRef = useRef();
    const [wid, setWid]=useState("0px")

    function AnimateAddRemvoe(page,status = false){
        try{
        if(status === false){
            if(page === 1){
                refia.current.classList.add("addProgress")
                refia.current.style.backgroundColor = "#2471A3"
                progressRef.current.style.width = "100px"
                setWid("0px")
            }else if(page === 2){
                refib.current.classList.add("addProgress")
                refib.current.style.backgroundColor = "#2471A3"
                progressRef.current.style.width = "200px"
                setWid("100px")
            }else if(page === 3){
                refic.current.classList.add("addProgress")
                refic.current.style.backgroundColor = "#2471A3"
                progressRef.current.style.width = "300px"
                setWid("200px")
            }else if(page === 4){
                refid.current.classList.add("addProgress")
                refid.current.style.backgroundColor = "#2471A3"
                progressRef.current.style.width = "400px"
                setWid("300px")
            }else if(page === 5){
                refie.current.classList.add("addProgress")
                refie.current.style.backgroundColor = "#2471A3"
                progressRef.current.style.width = "400px"
                setWid("400px")
            }
        }else{
            if(page === 1){
                refia.current.classList.remove("addProgress")
                refia.current.style.backgroundColor = "lightgray"
                progressRef.current.style.width = "0px"
                setWid("0px")
            }else if(page === 2){
                refib.current.classList.remove("addProgress")
                refib.current.style.backgroundColor = "lightgray"
                progressRef.current.style.width = "100px"
                setWid("0px")
            }else if(page === 3){
                refic.current.classList.remove("addProgress")
                refic.current.style.backgroundColor = "lightgray"
                progressRef.current.style.width = "200px"
                setWid("0px")
            }else if(page === 4){
                refid.current.classList.remove("addProgress")
                refid.current.style.backgroundColor = "lightgray"
                progressRef.current.style.width = "300px"
                setWid("0px")
            }else if(page === 5){
                refie.current.classList.remove("addProgress")
                refie.current.style.backgroundColor = "lightgray"
                progressRef.current.style.width = "400px"
                setWid("0px")
            }
        }
    }catch(err){console.warn(err)}
    }
    function Finished(){
        refia.current.classList.remove("addProgress")
        refia.current.style.backgroundColor = "lightgray"
        refib.current.classList.remove("addProgress")
        refib.current.style.backgroundColor = "lightgray"
        refic.current.classList.remove("addProgress")
        refic.current.style.backgroundColor = "lightgray"
        refid.current.classList.remove("addProgress")
        refid.current.style.backgroundColor = "lightgray"
        refie.current.classList.remove("addProgress")
        refie.current.style.backgroundColor = "lightgray"
        //progressRef.current.style.width= "0px"
    }
    useEffect(()=>{
        AnimateAddRemvoe(1)
        //console.log(pageno)
    },[pageno])

    // const onClickClose = ()=>{ 
    //     setPageno(1)
    //     setFormStatus(false)
    //     refSuccess.current.style.display = "none"
    // }
    const onClickBack = ()=>{
        if(pageno > 0){
        
            setPageno(pageno - 1)
        }
        AnimateAddRemvoe(pageno, true )
    }
    const onClickSave = ()=>{
        if(pageno < 5){
            setPageno(pageno + 1)
            
        }
        AnimateAddRemvoe(pageno+1,false)
    }
    const onClickFinish = ()=>{
        setFormStatus(false)
        refSuccess.current.style.display = "block"
    }
    const onClickSaveDraft = ()=>{

    }

    const incrementItems = ()=>{
        if(totalItems >= 0 && totalItems < 10){
            setTotalItems(totalItems + 1)
        }
    }
    const decrementItems = ()=>{
        if(totalItems > 0 ){
            setTotalItems(totalItems - 1)
        }
    }
    const onClickSwitch = (e)=>{
        e.stopPropagation()
        if(onStatus){
            btnrefs.current.classList.remove("addSwithbtn")
            switchRef.current.classList.remove("addSwitch")
            refIncDec.current.classList.remove("addIncDec")
        }else{
            btnrefs.current.classList.add("addSwithbtn")
            switchRef.current.classList.add("addSwitch")
            refIncDec.current.classList.add("addIncDec")
        }
        setOnStatus(!onStatus)
    }
    const onClickSwitchbtn = (e)=>{
        e.stopPropagation()
        if(onStatus){
            btnrefs.current.classList.remove("addSwithbtn")
            switchRef.current.classList.remove("addSwitch")
            refIncDec.current.classList.remove("addIncDec")
        }else{
            btnrefs.current.classList.add("addSwithbtn")
            switchRef.current.classList.add("addSwitch")
            refIncDec.current.classList.add("addIncDec")
        }
        setOnStatus(!onStatus)
    }
    const closeSuccess = ()=>{
        setPageno(1)
        Finished()
        //props.onClickClose()
        setFormStatus((previw)=> false)
        refSuccess.current.style.display = "none";
    }
    
    const onClickSelectItems = useCallback((selects)=>{
        console.warn(selects)
       setSelectval(selects)
    },[])
    const onClickSelectItemsb =  useCallback((selects)=>{
        console.warn(selects)
       setSelectvalb(selects)
    },[])
    const onClickSelectItemsc =  useCallback((selects)=>{
        console.warn(selects)
       setSelectvalc(selects)
    },[])
    const onClickSelectItemsd =  useCallback((selects)=>{
        console.warn(selects)
       setSelectvald(selects)
    },[])
    // form b
    const onClickSelectItemse =  useCallback((selects)=>{
        console.warn(selects)
       setSelectvale(selects)
    },[])
    const onClickSelectItemsf =  useCallback((selects)=>{
        console.warn(selects)
       setSelectvalf(selects)
    },[])
    // form c
    const onClickSelectItemsg =  useCallback((selects)=>{
        console.warn(selects)
       setSelectvalg(selects)
    },[])
    const onClickSelectItemsh =  useCallback((selects)=>{
        console.warn(selects)
       setSelectvalh(selects)
    },[])
    const openForm = ()=>{
        formRefs.current.classList.add("addOpenCloseForm")
    }
    const closeForm = ()=>{
        formRefs.current.classList.remove("addOpenCloseForm")
    }
    function onKeyDowna(e){
        setOptiona(e.target.value)
    }
    function onKeyDownb(e){
        setOptionb(e.target.value)
    }
    function onKeyDownc(e){
        setOptionc(e.target.value)
    }
    const onClickAdd = (e)=>{

    }
    const onClickAdda = (e)=>{
        if(changeValc.trim() !== "" && changeValc.trim().length > 1){
            setListItems((preview) => [...preview, changeValc])
        }
        setChangeValc("")
        console.log(listItems)
    }
    function onClickDeleteItems(index){
        let arr = listItems.filter((its, ind)=>{
            if(index !== ind) return its;
        })
        setListItems(arr)
    }
    function onChangea(e){
        setChangeVala(e.target.value)
    }
    function onChangeb(e){
        setChangeValb(e.target.value)
    }
    function onChangec(e){
        setChangeValc(e.target.value)
    }
    function onChanged(e){
        setChangeVald(e.target.value)
    }
    function onChangee(e){
        setChangeVale(e.target.value)
    }
    const PrintList = listItems.map((items, indexs)=>{
        return(<div key={items+indexs}
            style={{backgroundColor:"#D5DBDB", color:"#34495E",padding:"15px",borderRadius:"10px",
            position:"relative",paddingRight:"40px",display:"flex", alignItems:"center", marginBottom:"5px"}}
            >
            <div style={{display:"flex", alignItems:"center", flexDirection:"row"}}>
                <div style={{fontWeight:"800", fontSize:"2em", marginRight:"10px",
                marginTop:"-16px"}}>. </div> 
                <div>{items}</div>
            </div>
            <div onClick={()=> onClickDeleteItems(indexs)}
            style={{color:"#6E2C00", cursor:"pointer",position:"absolute",
            right:"10px",width:"30px",height:"30px",borderRadius:"30px",backgroundColor:"rgba(0,0,0,0.1)",
            display:"flex",fontWeight:"800",fontSize:"1.3em",
            justifyContent:"center",textAlign:"center",alignItems:"center"}}>X</div>
        </div>)
    })
    return(<>
        {/*<Title title="Form" /> */}
        <div className="form">
            <div className="form_header">
                <div className="form_header_left">
                    <div>Option 1 <i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                    <div>Option 2 <i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                    <div>Option 2 </div>
                </div>
                <div className="form_header_right">
                    <div>OnBoarding</div>
                    <div>Mobile : +91 1111111111</div>
                </div>
            </div>
            <div className="form_progress" >
                <div className="form_progress_items">
                    <div className="form_progress_connect" >
                        <div className="form_progress_connect_sub" ref={progressRef}
                        ></div>
                    </div>
                    <div className="form_progress_sub" style={{marginLeft:"0px"}}>
                        <div className="icon ia" ref={refia}>
                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                        </div>
                        <div>Stap 1</div>
                    </div>
                    <div className="form_progress_sub">
                        <div className="icon ib" ref={refib}>
                            <i className="fa fa-pie-chart" aria-hidden="true"></i>
                        </div>
                        <div>Stap 2</div>
                    </div>
                    <div className="form_progress_sub">
                        <div className="icon ic" ref={refic}>
                            <i className="fa fa-adjust" aria-hidden="true"></i>
                        </div>
                        <div>Stap 3</div>
                    </div>
                    <div className="form_progress_sub">
                        <div className="icon id" ref={refid}>
                            <i className="fa fa-file" aria-hidden="true"></i>
                        </div>
                        <div>Stap 4</div>
                    </div>
                    <div className="form_progress_sub" style={{marginRight:"0px"}}>
                        <div className="icon ie" ref={refie}>
                            <i className="fa fa-eye" aria-hidden="true"></i>
                        </div>
                        <div>Stap 5</div>
                    </div>
                </div>
                <div>
                    <Button onClick={onClickSaveDraft} > Save as draft </Button>
                </div>
            </div>

            {(pageno === 1)?
            <div className="form_form" style={{}} >
                <SelectOption list={[]} onClick={onClickSelectItems} value={selectval}/>
                <SelectOption list={[]} onClick={onClickSelectItemsb} value={selectvalb}/>
                <SelectOption list={[]} onClick={onClickSelectItemsc} value={selectvalc}/>
                <SelectOption list={[]} onClick={onClickSelectItemsd} value={selectvald}/>
            </div>: null}

            {(pageno === 2)?
            <div className="form_form" style={{}} >
                <SelectOption list={[]} onClick={onClickSelectItemse} value={selectvale}/>
                <SelectOption list={[]} onClick={onClickSelectItemsf} value={selectvalf}/>
                <div className="_switch">
                    <div className="_switch_label">Lorem ipsum dolor sit amet, consectetur adipi</div>
                    <div className="_switch_btn" onClick={(e)=>onClickSwitch(e)} ref={switchRef}>
                        <div className="_switchon">On</div>
                        <div className="_switchoff">Off</div>
                        <div className="_switch_btn_btn" ref={btnrefs} onClick={onClickSwitchbtn}></div>
                    </div>
                </div>
                <div className="_increment_decrement_" ref={refIncDec}>
                    <span>Total unit Available</span>
                    <button onClick={()=>decrementItems()}>
                        <i className="fa fa-minus" aria-hidden="true"></i>
                    </button>
                    <span>{totalItems}</span>
                    <button onClick={()=>incrementItems()}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            : null}

            {(pageno === 3)?
                <div className="form_form" style={{}} >
                    <SelectOption list={[]} onClick={onClickSelectItemsg} value={selectvalg} check={true}  onChecked={()=>{}}/>
                    <div className="label_b"> 
                        <div>Can't find Occasion?</div>
                        <div onClick={openForm}>Add Option</div>
                    </div>
                    <SelectOption list={[]} onClick={onClickSelectItemsh} value={selectvalh}/>
                    <div className="label_b"> 
                        <div>Can't find Theme?</div>
                        <div onClick={openForm}>Add Option</div>
                    </div>
                </div>
            :null}

            {(pageno === 4)?
                <div className="form_form" style={{}} >
                    <div className="label">Option 1</div>
                    <div>
                        <input type="text" onChange={(e)=>onChangea(e)} value={changeVala} 
                        placeholder="Enter Option"/>
                    </div>
                    <div className="label">Option 2</div>
                    <div>
                        <input type="text" onChange={(e)=>onChangeb(e)} value={changeValb} 
                        placeholder="Enter Option"/>
                    </div>
                    <div className="label">Option 3</div>
                    <div style={{backgroundColor:'white',marginTop:"5px",padding:"10px",borderRadius:"10px"}}>
                        <div className="label">Option A</div>
                        {(listItems.length > 0)?
                            <div>
                                {PrintList}
                            </div>
                        : null}
                        <div>
                            <input type="text" onChange={(e)=>onChangec(e)} value={changeValc} 
                            placeholder="Enter Option"/>
                        </div>
                        <div className="lable_btn" onClick={onClickAdda}>+Add</div>
                        <div className="label">Option B</div>
                        <div>
                            <input type="text" onChange={(e)=>onChanged(e)} value={changeVald} 
                            placeholder="Enter Option"/>
                        </div>
                        <div className="lable_btn" onClick={onClickAdd}>+Add</div>
                        <div className="label">Option C</div>
                        <div>
                            <input type="text" onChange={(e)=>onChangee(e)} value={changeVale} 
                            placeholder="Enter Option"/>
                        </div>
                        <div className="lable_btn" onClick={onClickAdd}>+Add</div>
                        {
                            (saveStatus)?
                            <div style={{borderRadius:"10px"}}>
                                <img alt="Image" src={require("./../../assets/image/img1.png")} width={478} height={100}
                                style={{borderRadius:"10px"}} />
                            </div>
                            :null
                        }
                        <div>
                            <Button style={{width:"100%",backgroundColor:"#F8F9F9"}} 
                                onClick={()=> setSaveStatus(!saveStatus)}
                            >Save</Button>
                        </div>
                    </div>
                </div>
            : null }

            {(pageno === 5)?
                <div className="form_form" style={{}} >
                    <div className="data_displays">
                        <div className="data_lists">
                            <div className="data_label">Country</div>
                            <div className="data_value">: Country Name</div>
                        </div>
                        <div className="data_lists">
                            <div className="data_label">State</div>
                            <div className="data_value">: State Name</div>
                        </div>
                        <div className="data_lists">
                            <div className="data_label">City</div>
                            <div className="data_value">: City Name</div>
                        </div>
                        <div className="data_lists">
                            <div className="data_label">Option A</div>
                            <div className="data_value">: Option 1</div>
                        </div>
                        <div className="data_lists">
                            <div className="data_label">Option B</div>
                            <div className="data_value">: Option 2</div>
                        </div>
                        <div className="data_lists">
                            <div className="data_label">Option C</div>
                            <div className="data_value">: Option 3</div>
                        </div>
                        <div className="data_lists">
                            <div className="data_label">Option D</div>
                            <div className="data_value">: Option 4</div>
                        </div>
                        <div className="data_lists">
                            <div className="data_label">Option E</div>
                            <div className="data_value">: Option 5</div>
                        </div>
                        <div className="data_lists">
                            <div className="data_label">Option F</div>
                            <div className="data_value">: Option 6</div>
                        </div>
                        <div className="data_lists">
                            <div className="data_label">Option G</div>
                            <div className="data_value">
                                <div>: Option 7</div>
                                <div>1 - Lorem ipsum dolor sit amet.</div>
                                <div>1 - Lorem ipsum dolor sit amet.</div>
                                <div style={{borderWidth:1,borderColor:'lightgray',height:"1px",backgroundColor:"lightgray",margin:"10px"}}></div>
                                <div style={{marginBottom:"10px"}}>Service Description - </div>
                                <div>1 - Lorem ipsum dolor sit amet.</div>
                                <div>1 - Lorem ipsum dolor sit amet.</div>
                                <a href="/" onClick={(e)=>e.preventDefault()}
                                style={{color:"blue", cursor:"pointer", textAlign:"end",marginLeft:"130px",width:"70px"}}>View More</a>
                            </div>
                        </div>
                        <div className="data_lists">
                            <div className="data_label">Uploaded Image</div>
                            <div className="data_value">: </div>
                        </div>
                        <div className="data_image">
                            <div className="data_img">
                                <img alt="image1 " src={require("./../../assets/image/img2.png")} 
                                style={{width:"200px", height:"150px"}} />
                            </div>
                            <div className="data_img">
                                <img alt="image1 " src={require("./../../assets/image/img1.png")} 
                                style={{width:"200px", height:"150px"}} />
                            </div>
                            <div className="data_img">
                                <img alt="image1 " src={require("./../../assets/image/img3.png")} 
                                    style={{width:"200px", height:"150px"}} />
                            </div>
                            <div className="data_img">
                                <img alt="image1 " src={require("./../../assets/image/img4.png")} 
                                    style={{width:"200px", height:"150px"}} />
                            </div>
                            <div className="data_img">
                                <img alt="image1 " src={require("./../../assets/image/img6.png")} 
                                    style={{width:"200px", height:"150px"}} />
                            </div>
                            <div className="data_img">
                                <img alt="image1 " src={require("./../../assets/image/img7.png")} 
                                    style={{width:"200px", height:"150px"}} />
                            </div>
                            <div className="data_img">
                                <img alt="image1 " src={require("./../../assets/image/img8.png")} 
                                    style={{width:"200px", height:"150px"}} />
                            </div>
                        </div>
                    </div>
                </div>
            : null }

            <div className="opencloseform" ref={formRefs}>
                <div className="opencloseform_sub">
                    <div className="opencloseform_close">
                        <div>Add Options</div>
                        <div onClick={closeForm}>X</div>
                    </div>
                    <div className="opencloseform_input">
                        <div className="label">Option 1</div>
                        <div>
                            <input type="text" onChange={(e)=>onKeyDowna(e)} value={optiona} 
                            placeholder="Enter Option"/>
                        </div>
                        <div className="label">Option 2</div>
                        <div>
                            <textarea type="text" onInput={(e)=>onKeyDownb(e)} value={optionb}
                            placeholder="Enter Option" cols={40} rows={10}/>
                        </div>
                        <div className="label">Option 3</div>
                        <div>
                            <input type="text" onChange={(e)=>onKeyDownc(e)} value={optionc} 
                            placeholder="Enter Option"/>
                        </div>
                        <div style={{textAlign:"center"}}>
                            <Button  onClick={closeForm} style={{backgroundColor:"#2874A6",color:"white"}}>Next</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="success" ref={refSuccess}>
                <div className="success_sub">
                    <div className="success_icon">
                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                    </div>
                    <div className="success_text">Successfully Saved</div>
                    <div className="success_close" onClick={()=>{
                        closeSuccess()
                        props.onClickClose()
                        }
                    }
                    >X</div>
                </div>
            </div>
            <div className="form_btn">
                <div className="form_btn_left">
                    {(pageno === 1 ) ?
                        <Button onClick={props.onClickClose}>Close</Button>
                        :
                        <Button onClick={onClickBack}>Back</Button>
                    }
                </div>
                <div  className="form_btn_right">
                    {(formStatus === false && pageno <= 4)? 
                        ((pageno === 4 )?
                            <Button onClick={onClickSave}>Save</Button>
                            :
                            <Button onClick={onClickSave}>Next</Button>)
                        :
                        <Button onClick={onClickFinish}>Finish</Button>
                    }
                </div>
            </div>

        </div>
    </>)
}