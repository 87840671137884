import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Title from "./Title";

export default function NotFound(props){
    const navigate = useNavigate()
    const onClickGoback = ()=>{
        navigate("/")
    }
    return(<>
        <Title title="Page Not Found 404" />
        <div className="page404">
            <div>
                <h3>Page not found 404</h3>
                <Button onClick={onClickGoback} className="button">Go Back</Button>
            </div>
        </div>
    </>)
}