import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import { useParams,Navigate, Link, useNavigate, redirect} from "react-router-dom"

import Home from "./Home";
import InputData from './InputData';
import NotFound from "./NotFound";
import DisplayData from "./DisplayData";

export default function Routing(props){
    //let {userId} = useParams()
    //<Navigate to="/dashboard" replace={true} />
    //<Link to ="path">path</Link>
    //const navigate = useNavigate(); navigate("/pathname". {})
    //redirect("/login");
    return(<>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/form" Component={InputData} />
                <Route path="/user/:userId" Component={DisplayData} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    </>)
}